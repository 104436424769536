import React from 'react';

import SEO from '../components/seo';

const AdvisingPage = () => (
  <div>
    <SEO title="Advising, Fractional VPE" />
    <h1>How can I help you?</h1>
    <p></p>
    <p>If you have an interesting technology or team problem/opportunity <a href="mailto:paul@pauldowman.com">send me a message</a> and let's talk.</p>
  </div>
);

export default AdvisingPage;
